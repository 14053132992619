<template>
  <div>
    <div>
      <a-form-model
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="所属公司">
          <a-select
            v-model="formInline.ptBcId"
            class="search-expand-value"
            :disabled="type == 'edit' ? true : false"
          >
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上级类别">
          <a-tree-select
            v-model="formInline.ptSupId"
            class="search-expand-value"
            allow-clear
            tree-default-expand-all
            :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
          >
            <a-tree-select-node
              v-for="opt in filters.GetProjectType"
              :key="opt.ptId"
              :value="opt.ptId"
              :title="opt.ptName"
            >
              <!-- :selectable="false" 是否可选 -->
              <a-tree-select-node
                v-for="item in opt.children"
                :key="item.ptId"
                :value="item.ptId"
                :title="item.ptName"
              >
              </a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-model-item>

        <a-form-model-item label="套餐类型名称">
          <a-input v-model="formInline.ptName" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import {
  GetPackageTypeList,
  AddPackageType,
  UpdatePackageType,
} from "@/api/apiJF/template";
export default {
  name: "MaterialCategoriesEdit",
  components: {},
  props: {
    record: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      formInline: {
        // ptName: "",
        // ptBcId: "",
        // ptSupId: "",
      },
      formInlineEdit: {
        ptId: "", //类别id
        ptBcId: "", //所属id
        ptName: "", //名称
        ptSupId: "", //上级类别id
        createTime: "", //创建时间
      },
      filters: { GetCompanies: [], GetProjectType: [] },
    };
  },
  created() {
    // 获取所属信息
    GetCompanies().then((res) => {
      this.filters.GetCompanies = res.data;
    });

    let ptBcIds = this.record.ptBcIds;
    console.log(this.type, this.record);
    // this.GetProjectType(ptBcIds);
  },
  mounted() {
    if (this.type == "edit") {
      for (let key in this.formInlineEdit) {
        for (let el in this.record) {
          if (key == el) {
            this.formInlineEdit[key] = this.record[el];
          }
        }
      }
      if (this.formInlineEdit.ptSupId == null) {
        this.formInlineEdit.ptSupId = 0;
      }
      this.formInline = this.formInlineEdit;
    } else {
    }
  },
  watch: {
    "formInline.ptBcId": {
      handler(newValue) {
        console.log("formInline.ptBcId", newValue);
        this.GetPackageTypeList(newValue);
      },
    },
  },
  methods: {
    // 获取所有类别名称及id
    GetPackageTypeList(ptBcIds) {
      let queryParam = {
        ptBcIds: ptBcIds,
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      this.filters.GetProjectType = [{ ptId: "0", ptName: "主类目" }];
      return GetPackageTypeList(params).then((res) => {
        res.data.forEach((el) => {
          this.filters.GetProjectType.push(el);
        });
      });
    },
    // 提交按钮
    handleSubmit(e) {
      this.updata();
    },
    updata() {
      if (this.type == "add") {
        // console.log("提交");
        let form = {
          ptName: this.formInline.ptName,
          ptSupId: this.formInline.ptSupId,
          ptBcId: this.formInline.ptBcId,
        };

        let addVerify = Object.keys(form);
        const orderParam = this.$order(form, addVerify);
        const params = {
          ...orderParam,
          verify: this.$verify(orderParam, addVerify),
        };
        console.log("add", params, form, this.formInline);
        AddPackageType(params).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success("添加成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.type == "edit") {
        let params = this.formInlineEdit;
        UpdatePackageType(params).then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
</style>